import * as React from "react";
import { graphql } from "gatsby";

import { Layout, NewsBlock, Title } from "../components";

const IndexPage = ({ data }) => {
  return (
    <>
      <title>
        Nieuws | Cémpes Vastgoed - Dé alleskunner voor uw vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="Nieuws" />

        <div className="mt-5 grid sm:grid-cols-1 gap-2 max-w-screen-md">
          {data.allNews.nodes.map((newsItem) => (
            <NewsBlock
              className=""
              key={newsItem.id}
              title={newsItem.title}
              date={newsItem.date}
              content={newsItem.content}
            />
          ))}
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allNews: allDatoCmsNews(sort: { order: ASC, fields: date }) {
      nodes {
        title
        slug
        content {
          value
        }
        date
        id
      }
    }
  }
`;
